import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import './liveStreamPage.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import api from '../../config/api';
import startPublish from './newPublish';
// import { socket } from '../../config/socket';
import stopPublish from './stopPublish';
import EyeIcon from '../../assets/icons/EyeIcon';
import PlayerSkin from '../../components/PlayerSkin/PlayerSkin';
import useGetViewers from '../../hooks/useGetViewers';
import CountdownTimer from '../../components/CountdownTimer/CountdownTimer';
import LiveStreamStoppedPage from '../LiveStreamStoppedPage/LiveStreamStoppedPage';
import EndLiveStreamModal from './EndLiveStreamModal';
import ROUTES from '../../routes';
import Webcam from 'react-webcam';
import { LIVE_STREAM_STATUS, STREAM_TYPE } from '../../utils/constant';
import ClockTimer from '../../components/ClockTimer/ClockTimer';
import MyVideoLoading from '../../components/Loading/MyVideoLoading';
import useGetTask from '../../hooks/useGetTask';
import { toast } from 'react-toastify';
import useLiveStreamState from '../../hooks/useLiveStreamState';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPublishApplicationName,
  setPublishAudioTrackDeviceId,
  setPublishSignalingURL,
  setPublishStreamName,
  setPublishVideoTrack1DeviceId,
} from '../../store/publishSettingSlice';
import useSocket from '../../hooks/useSocket';

const LiveStreamPage = () => {
  const { taskId, streamId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const publishSettings = useSelector((state) => state.publishSettings);
  const webrtcPublish = useSelector((state) => state.webrtcPublish);
  const { connectSocket, disconnectSocket } = useSocket();
  const [videoStream, setVideoStream] = useState(null);
  const [peerConnection, setPeerConnection] = useState(null);
  const [websocket, setWebsocket] = useState(null);

  const [duration, setDuration] = useState(null);
  const [endLiveStreamModal, setEndLiveStreamModal] = useState(false);

  const { data: task } = useGetTask(taskId);
  const { data: liveStreamState } = useLiveStreamState(streamId);
  const isLiveStreamStarted = liveStreamState === LIVE_STREAM_STATUS.LIVE;

  const { data: viewers } = useGetViewers(streamId, isLiveStreamStarted);
  const { mutate: streamStarted } = useMutation({
    mutationFn: (streamId) => api.put(`/api/stream/${streamId}/started`),
    onSuccess: ({ data }) => {
      setDuration(data.data?.duration);
    },
  });

  const stopCamera = useCallback(async () => {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => {
        track.stop();
      });
      setVideoStream(null);
      stopPublish(peerConnection, websocket, {
        onSetPeerConnection: (result) => {
          console.log('PeerConnection: ', result.peerConnection);
          setPeerConnection(result.peerConnection);
        },
        onSetWebsocket: (result) => {
          console.log('Websocket: ', result.websocket);
          setWebsocket(result.websocket);
        },
        onPublishStopped: () => {
          console.log('Publish Stopped');
        },
      });
      // socket.disconnect();
      disconnectSocket();
      try {
        await api.put(`/api/stream/${streamId}/stop`);
      } catch (error) {
        console.log(error);
      }
      navigate(ROUTES.WELCOME);
    }
  }, [dispatch, navigate, peerConnection, streamId, videoStream, websocket]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setVideoStream(stream);
        const audioTrack = stream.getAudioTracks()[0];
        const videoTrack = stream.getVideoTracks()[0];
        dispatch(setPublishAudioTrackDeviceId(audioTrack.id));
        dispatch(setPublishVideoTrack1DeviceId(videoTrack.id));
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
        toast.error("Couldn't access camera");
      });
  }, [dispatch]);

  useEffect(() => {
    const sourceConnectionInformation = task?.data?.streamDetails?.sourceConnectionInformation;
    dispatch(setPublishSignalingURL(sourceConnectionInformation?.sdp_url));
    dispatch(setPublishApplicationName(sourceConnectionInformation?.application_name));
    dispatch(setPublishStreamName(sourceConnectionInformation?.stream_name));
  }, [dispatch, task?.data?.streamDetails?.sourceConnectionInformation]);

  useEffect(() => {
    if (liveStreamState === LIVE_STREAM_STATUS.LIVE) {
      console.log(publishSettings);
      // streamStarted(streamId);
      connectSocket();
      startPublish(publishSettings, videoStream, websocket, {
        onError: (error) => {
          console.log('Websocket Error: ', error?.message);
        },
        onConnectionStateChange: (result) => {
          console.log('ConnectionStateChange: ', result.connected);
          if (result?.connected === true) streamStarted(streamId);
        },
        onSetPeerConnection: (result) => {
          console.log('PeerConnection: ', result.peerConnection);
          setPeerConnection(result.peerConnection);
        },
        onSetWebsocket: (result) => {
          console.log('Websocket: ', result.websocket);
          setWebsocket(result.websocket);
          dispatch(setWebsocket(result.websocket));
        },
        onSetSenders: (senders) => {
          console.log('OnSetSenders: ', senders);
        },
      });
    }
  }, [dispatch, liveStreamState, publishSettings, streamId, streamStarted, websocket]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // For older browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  // useEffect(() => {
  //   if (socket && socket.connected) {
  //     socket.emit('pong', { message: 'pong', time: Date.now() });
  //   }
  // }, []);

  // useEffect(() => {
  //   const pingInterval = setInterval(() => {
  //     if (socket && socket.connected) {
  //       socket.on('ping', (data) => {
  //         console.log('Received ping from server:', data.message, 'at', new Date(data.time));

  //         // Optionally respond with a pong message
  //         socket.emit('pong', { message: 'pong', time: Date.now() });
  //       });
  //     }
  //   }, 25000);

  //   return () => clearInterval(pingInterval);
  // }, []);

  // prevent back button
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    const handlePopState = (event) => {
      window.history.pushState(null, null, window.location.pathname);
      console.log('Back button is disabled');
      setEndLiveStreamModal(true);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  if (!liveStreamState || liveStreamState === LIVE_STREAM_STATUS.STARTING) return <MyVideoLoading />;
  // if (liveStreamState && liveStreamState === LIVE_STREAM_STATUS.STOPPED) return <LiveStreamStoppedPage />;

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          mx: {
            sm: '-24px',
            xs: '-16px',
          },
          height: '100dvh',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
            zIndex: 2,
          }}
        >
          <Stack
            spacing={0.5}
            direction={'row'}
            sx={{
              padding: '4px 8px 4px 8px',
              backgroundColor: (theme) => theme.palette.blur.main,
              color: '#fff',
              borderRadius: '4px',
            }}
          >
            <EyeIcon />
            <Typography variant={'caption_bold'}>{viewers}</Typography>
          </Stack>
          {duration > 0 && task?.data?.type === STREAM_TYPE.REQUEST ? (
            <CountdownTimer duration={duration} />
          ) : (
            <ClockTimer />
          )}
          <Button
            variant='contained'
            onClick={() => setEndLiveStreamModal(true)}
            sx={{
              color: '#fff',
              fontSize: '9px',
              fontWeight: 600,
              backgroundColor: '#FF335B',
              borderRadius: 50,
              '&:hover': {
                backgroundColor: '#FF335B',
                boxShadow: 'none',
              },
            }}
          >
            End Live Stream
          </Button>
        </Box>
        <Box
          sx={{
            position: 'relative',
            top: 66,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
            zIndex: 2,
          }}
        >
          <Typography variant={'h5_semibold'}>{task?.data?.title}</Typography>
        </Box>
        <PlayerSkin taskId={taskId} streamId={streamId} task={task} duration={duration} />
        <Webcam
          mirrored={true}
          videoConstraints={{ facingMode: 'user' }}
          style={{
            position: 'absolute',
            textAlign: 'center',
            zIndex: 1,
            left: 0,
            top: 0,
            width: '100%',
            height: '100dvh',
            objectFit: 'cover',
          }}
        />
      </Box>

      <EndLiveStreamModal
        open={endLiveStreamModal}
        handleClose={() => setEndLiveStreamModal(false)}
        endLiveStream={stopCamera}
      />
    </>
  );
};

export default LiveStreamPage;
