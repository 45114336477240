import { Box } from '@mui/material';
import React, { useRef } from 'react';
import MenuHeader from '../../MenuHeader';
import LiveStreamCard from './LiveStreamCard';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../routes';
import useLiveStream from '../../../../hooks/useLiveStream';
import Loading from '../../../../components/Loading/Loading';
import RecordedStreamCard from './RecordedStreamCard';

const LiveStreamComponent = () => {
  const navigate = useNavigate();
  const { data: allLiveStreams, isLoading } = useLiveStream();
  console.log(allLiveStreams);
  let sliderRef = useRef(null);
  if (isLoading) return <Loading />;
  return (
    <Box mt={4}>
      <MenuHeader
        title={'Live Streams'}
        onClick={() => {
          navigate(ROUTES.LIVE_ALL + '?isBottomNavigation=true');
        }}
        isViewAll={allLiveStreams?.data?.totalCount > 0}
      />

      <Box mt={2} sx={{ overflowX: 'hidden' }}>
        <swiper-container ref={sliderRef} slides-per-view={1.5} pagination='false' style={{ width: '100%' }}>
          {allLiveStreams.data?.result?.map((liveShow) => (
            <swiper-slide style={{ width: '100%', display: 'flex', alignItems: 'center' }} key={liveShow._id}>
              {liveShow?.streamStatus === 'STOPPED' ? (
                <RecordedStreamCard key={liveShow.title} data={liveShow} />
              ) : (
                <LiveStreamCard key={liveShow.title} data={liveShow} />
              )}
            </swiper-slide>
          ))}
        </swiper-container>
      </Box>
    </Box>
  );
};

export default LiveStreamComponent;
