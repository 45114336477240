import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import TopRequestCard from '../WelcomePage/components/TopRequestComponent/TopRequestCard';
import NoStreamRequestCard from '../WelcomePage/components/TopRequestComponent/NoStreamRequestCard';
import Loading from '../../components/Loading/Loading';
import api from '../../config/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import Stack from '@mui/material/Stack';
import { TOP_REQUEST_CARD_HEIGHT } from '../../utils/constant';

const TopRequestComponent = ({ setTopRequestComponentHeight, searchQuery }) => {
  const getTopRequest = async ({ pageParam, queryKey }) => {
    const [_, { query }] = queryKey;
    const URL =
      searchQuery.index === 1
        ? `/api/user/task?page=${pageParam}&size=5&search=${query}`
        : `/api/user/task?page=${pageParam}&size=5`;
    const { data } = await api.get(URL);
    setTopRequestComponentHeight && setTopRequestComponentHeight(data.data.totalCount * TOP_REQUEST_CARD_HEIGHT + 45); // 45 request text height
    return data;
  };
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['topRequest', { query: searchQuery.query }],
    queryFn: getTopRequest,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const fetchedData = allPages.length * 5;
      const totalData = lastPage.data.totalCount;
      return fetchedData < totalData ? allPages.length + 1 : undefined;
    },
  });

  const topRequests = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      return [...acc, ...page?.data?.result];
    }, []);
  }, [data]);
  if (isLoading) return <Loading />;
  return (
    <Box width='100%'>
      <MenuHeader title={'Requests'} />
      <Stack spacing={1.5} sx={{ pb: 1 }}>
        {topRequests.length > 0 ? (
          <>
            {topRequests?.map((topRequestCard, index) => (
              <TopRequestCard
                key={topRequestCard?._id}
                data={topRequestCard}
                isLoading={isLoading}
                fetchNextPage={fetchNextPage}
                isFetching={isFetching}
                hasNextPage={hasNextPage}
                index={index}
                totalCount={topRequests.length}
              />
            ))}
          </>
        ) : (
          <NoStreamRequestCard />
        )}
      </Stack>
      {/*<CustomPagination handleChange={handleChange} page={page} size={topRequests?.data?.totalCount} />*/}
    </Box>
  );
};

export default TopRequestComponent;
